import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import Masonry from 'react-masonry-component'

import {
  Title,
  Section,
  Box,
  Text,
  ButtonOutline,
  ListNav,
} from '../../components/Core'
import RotateImg from '../../components/RotateImg'
import { gridWorks1 } from '../../data'
import { useWindowSize } from '../../hooks'
import { breakpoints } from '../../utils'

const WorkCard = styled(Box)``
const WorkText = styled(Box)``

const Grid = (props) => {
  const size = useWindowSize()

  const masonryOptions = {
    transitionDuration: 1000,
  }

  return size.width < breakpoints.lg ? (
    <Masonry
      options={masonryOptions}
      className={'masonry-grid row'}
      {...props}
    />
  ) : (
    <Row {...props} />
  )
}

const Works = () => {
  const [items, setItems] = useState([])
  const [activeLink, setActiveLink] = useState('*')

  useEffect(() => {
    setItems(gridWorks1)
  }, [])

  const filterBy = (cat) => {
    if (cat === '*') {
      setActiveLink('*')
      setItems(gridWorks1)
    } else {
      const filteredItems = gridWorks1.filter((item) => {
        return item.categories.indexOf(cat) !== -1
      })
      setActiveLink(cat)
      setItems(filteredItems)
    }
  }

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <Container>
          <Box
            mb="2.5rem"
            ml="-1.75rem"
            className="d-flex justify-content-center"
          >
            <ListNav className="nav">
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === '*' ? 'active' : null
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    filterBy('*')
                  }}
                >
                  All works
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'branding' ? 'active' : null
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    filterBy('branding')
                  }}
                >
                  Branding
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'ux-design' ? 'active' : null
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    filterBy('ux-design')
                  }}
                >
                  UX Design
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === 'photography' ? 'active' : null
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    filterBy('photography')
                  }}
                >
                  Photography
                </a>
              </li>
            </ListNav>
          </Box>
        </Container>
        <Container>
          <Grid>
            {items.map((item, index) => (
              <Col md="6" key={index} className="filtr-item">
                <WorkCard className="position-relative" mb="30px">
                  <RotateImg link={item.link} imgSrc={item.thumbnail} />
                  <Box pt="2.125rem">
                    <WorkText className="overflow-hidden text-center">
                      <Text variant="tag" mb="0.5rem">
                        {item.categories[0]}
                      </Text>
                      <Title variant="cardLg">
                        <Link to={item.link}>{item.brand} </Link>
                      </Title>
                    </WorkText>
                  </Box>
                </WorkCard>
              </Col>
            ))}
          </Grid>
          <Box pt="3rem" className="text-center">
            <ButtonOutline>Load more works</ButtonOutline>
          </Box>
        </Container>
      </Section>
    </>
  )
}

export default Works
